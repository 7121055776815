import React from "react"
import { Link } from "gatsby"

const SinglePost = ({ frontmatter }) => {
  const { category, post_id, slug, title, preview } = frontmatter

  return (
    <article
      className={`post ${category === "barca" ? "post-barca" : "post-other"}`}
    >
      <Link to={`/${post_id}-${slug}`}>
        <div className="post-info">
          <h4 className="post-title">{title}</h4>
          <p className="post-desc">{preview}</p>
        </div>
      </Link>
    </article>
  )
}

export default SinglePost
